import theModalAntecedente from './theModalAntecedente';
import theModalTratamientoProceso from './theModalTratamientoProceso';
import theModalSignosVitales from './theModalSignosVitales';

const consulaExternaStore = {
  theModalAntecedente,
  theModalTratamientoProceso,
  theModalSignosVitales,
};

export default consulaExternaStore;
