import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { MODALIDAD_FACTURACION } from '@/utils/globalConventions';
import validarParametroFacturacionSiat from '@/store/modules/facturacion/utils/siatValidations';

export default {
  namespaced: true,
  state: {
    modalidadesFacturas: [],
    hasOptionSinFactura: false,
    tipo_factura: MODALIDAD_FACTURACION.COMPUTARIZADA,
    tipoContingencias: [],
    tipoContingenciaSelected: null,
    tipoEmisions: [],
    tipoEmisionSelected: null,
    siatLeyendaSelected: null,
    cafc: null,
    disabledStatusFactura: false,
    checkFacturaTercero: false,
    datepicker: new Date(),
    disabledDatePicker: false,
    pacienteSelected: null,
    indentificacionSelected: null,
    pacientes: [],
    isLoadingComboPacientes: false,
    razonSocial: null,
    nit: null,
    datoFactura: false,
    showDatoFactura: true,
    checkSeguroEstado: false,
    checkValorCero: false,
    checkHability: false,
    checkHabilityValorCero: false,
    nroFactura: null,
    aseguradoraSelected: null,
    comboAseguradora: [],
    statusComboMoneda: false,
    monedaSelected: null,
    comboMoneda: [],
    tipoCambio: null,
    tc: null,
    medicoSelected: null,
    medicos: [],
    configuracion_factura_electronica: null,
    siatMedioPagoMedico: [],
    siatTipoDocumentoMedico: [],
    queryTemporalPaciente: '',
    temporaPageComboPacientes: 1,
    totalSearchPacientes: 0,
    facturaNumeroManual: false,
    tratamientosDisponibles: false,
    medicoTratantes: [],
    medicoDerivadors: [],
    numeroReserva: null,
    complemento: null,
    tipoDocumentos: [],
    numeroTratamiento: null,
  },
  getters: {
    getField,
    getDisabledStatusFactura: (state) => state.disabledStatusFactura,
    getCheckFacturaTercero: (state) => state.checkFacturaTercero,
    getDatePicker: (state) => state.datepicker,
    getDisabledDatePicker(state, getters, rootState, rootGetters) {
      const isBloqueadoFecha = rootGetters['main/isBloqueadoFecha'];
      if (
        isBloqueadoFecha
        || getters.isTipoFacturaComputarizadaEnLinea
        || getters.isTipoFacturaElectronicaEnLinea
      ) {
        return true;
      }
      return state.disabledDatePicker;
    },
    getPacienteSelected: (state) => state.pacienteSelected,
    getPacientes: (state) => state.pacientes,
    getCheckSeguroEstado: (state) => state.checkSeguroEstado,
    getAseguradoraSelected: (state) => state.aseguradoraSelected,
    getComboAseguradora: (state) => state.comboAseguradora,
    getStatusComboMoneda(state, getters, rootState, rootGetters) {
      const isBloqueado = rootGetters['main/isBloqueadoCambiarMoneda'];
      if (isBloqueado) {
        return true;
      }
      return state.statusComboMoneda;
    },
    getMedicoSelected: (state) => state.medicoSelected,
    showMedicoSelected(state) {
      if (state.medicoSelected != null) {
        return state.medicoSelected.nombre;
      }
      return '';
    },
    getMedicos: (state) => state.medicos,
    getMedicosTratantes: (state) => state.medicoTratantes,
    getTipoFactura: (state) => state.tipo_factura,
    isTipoFacturaSinFactura: (state) => state.tipo_factura === MODALIDAD_FACTURACION.SIN_FACTURA,
    isTipoFacturaConFactura: (state) => state.tipo_factura !== MODALIDAD_FACTURACION.SIN_FACTURA,
    isTipoFacturaComputarizada: (state) => state.tipo_factura === MODALIDAD_FACTURACION.COMPUTARIZADA,
    isTipoFacturaManual: (state) => state.tipo_factura === MODALIDAD_FACTURACION.MANUAL,
    isTipoFacturaComputarizadaEnLinea: (state) => state.tipo_factura === MODALIDAD_FACTURACION.COMPUTARIZADA_EN_LINEA,
    isTipoFacturaElectronicaEnLinea: (state) => state.tipo_factura === MODALIDAD_FACTURACION.ELECTRONICA_EN_LINEA,
    isTipoPreVenta: (state) => state.tipo_factura === MODALIDAD_FACTURACION.PREVENTA,
    getMonedaSecundaria: (state) => {
      const MONEDA = state.comboMoneda.find((el) => el.is_principal === 0);
      let { tc } = MONEDA;
      if (state.tipoCambio !== null) {
        tc = state.tipoCambio;
      }
      const MONEDA_SECUNDARIA = {
        id: MONEDA.id,
        tc,
        is_principal: MONEDA.is_principal,
      };
      return MONEDA_SECUNDARIA;
    },
    getTipoEmisionsComputed(state) {
      if (state.checkFacturaTercero) {
        if (state.medicoSelected.configuracion_factura_tercero === 0) {
          const tipoEmisions = state.medicoSelected.siat_tipo_emisions.filter((item) => (
            item.cod_clasificador === 1 || item.cod_clasificador === 2
            // en linea y fuera de linea
          ));
          return tipoEmisions;
        }
      }
      return state.tipoEmisions;
    },
    getMedicoDerivador: (state) => state.medicoDerivadors,
  },
  mutations: {
    updateField,
    setModalidadesFacturas: (state, data) => { state.modalidadesFacturas = data; },
    setHasOptionSinFactura: (state, data) => { state.hasOptionSinFactura = data; },
    setTipoFactura: (state, data) => { state.tipo_factura = data; },
    setTipoContingencias: (state, data) => { state.tipoContingencias = data; },
    setTipoContingenciaSelected: (state, data) => { state.tipoContingenciaSelected = data; },
    setTipoEmisions: (state, data) => { state.tipoEmisions = data; },
    setTipoEmisionSelected: (state, data) => { state.tipoEmisionSelected = data; },
    setSiatLeyendaSelected: (state, data) => { state.siatLeyendaSelected = data; },
    setCafc: (state, data) => { state.cafc = data; },
    setDisabledStatusFactura: (state, data) => { state.disabledStatusFactura = data; },
    setCheckFacturaTercero: (state, data) => { state.checkFacturaTercero = data; },
    setDatePicker: (state, data) => { state.datepicker = data; },
    setDisabledDatePicker: (state, data) => { state.disabledDatePicker = data; },
    setPacienteSelected: (state, data) => { state.pacienteSelected = data; },
    setIndentificacionSelected: (state, data) => { state.indentificacionSelected = data; },
    setPacientes: (state, data) => { state.pacientes = data; },
    setIsLoadingComboPacientes: (state, data) => { state.isLoadingComboPacientes = data; },
    setRazonSocial: (state, data) => { state.razonSocial = data; },
    setNit: (state, data) => { state.nit = data; },
    setDatoFactura: (state, data) => { state.datoFactura = data; },
    setShowDatoFactura: (state, data) => { state.showDatoFactura = data; },
    setCheckSeguroEstado: (state, data) => { state.checkSeguroEstado = data; },
    setNroFactura: (state, data) => { state.nroFactura = data; },
    setAseguradoraSelected: (state, data) => { state.aseguradoraSelected = data; },
    setComboAseguradora: (state, data) => { state.comboAseguradora = data; },
    setMonedaSelected: (state, data) => { state.monedaSelected = data; },
    setStatusComboMoneda: (state, data) => { state.statusComboMoneda = data; },
    setComboMoneda: (state, data) => { state.comboMoneda = data; },
    setTipoCambio: (state, data) => { state.tipoCambio = data; },
    setTC: (state, data) => { state.tc = data; },
    setMedicoSelected: (state, data) => { state.medicoSelected = data; },
    setMedicos: (state, data) => { state.medicos = data; },
    setMedicosTratantes: (state, data) => { state.medicoTratantes = data; },
    setQueryTemporalPaciente: (state, data) => { state.queryTemporalPaciente = data; },
    setTemporaPageComboPacientes: (state, data) => { state.temporaPageComboPacientes = data; },
    setTotalSearchPacientes: (state, data) => { state.totalSearchPacientes = data; },
    setFacturaNumeroManual: (state, data) => { state.facturaNumeroManual = data; },
    setTratamientosDisponibles: (state, data) => { state.tratamientosDisponibles = data; },
    setCheckValorCero: (state, data) => { state.checkValorCero = data; state.checkHabilityValorCero = data !== 1 && data !== null; },
    setConfigFacturaElectronicaMedico: (state, data) => { state.configuracion_factura_electronica = data; },
    setSiatMedioPagoMedico: (state, data) => { state.siatMedioPagoMedico = data; },
    setSiatTipoDocumentoMedico: (state, data) => { state.siatTipoDocumentoMedico = data; },
    setMedicoDerivador: (state, data) => { state.medicoDerivadors = data; },
    setNumeroReserva: (state, data) => { state.numeroReserva = data; },
    setNumeroTratamiento: (state, data) => { state.numeroTratamiento = data; },
    setComplemento: (state, data) => { state.complemento = data; },
    setTipoDocumentos: (state, data) => { state.tipoDocumentos = data; },
    cargarSeguroSelectedById(state, id) {
      if (id === null) {
        return;
      }
      state.aseguradoraSelected = state.comboAseguradora.find((el) => el.id === id);
    },
    cargarComboMoneda(state, data) {
      const MONEDAS = data;
      state.comboMoneda = MONEDAS;
      const ITEM_SELECTED = MONEDAS.find((el) => el.is_principal === 1);
      state.monedaSelected = ITEM_SELECTED;
      state.tc = ITEM_SELECTED.tc;
    },
    cargarMedicoSelectedById(state, id) {
      if (id === null) {
        return;
      }
      state.medicoSelected = state.medicos.find((el) => el.id === id);
    },
    cargarMonedaSelectedById(state, id) {
      const ITEM_MONEDA = state.comboMoneda.find((el) => el.id === id);
      state.monedaSelected = ITEM_MONEDA;
    },
    cargarTipoCambio(state, tc) {
      if (state.monedaSelected.is_principal === 1) {
        return;
      }
      state.tipoCambio = tc;
    },
    cargarMedicoDerivador(state, id) {
      if (id === null) {
        return;
      }
      state.medicoDerivadorSelected = state.medicoDerivadors.find((el) => el.id_medico === id);
    },
  },
  actions: {
    async obtenerListaPacientes({ commit, state }, query) {
      commit('setIsLoadingComboPacientes', true);
      if (query !== null) {
        commit('setQueryTemporalPaciente', query);
      } else {
        commit('setTemporaPageComboPacientes', state.temporaPageComboPacientes + 1);
      }
      try {
        const REQUEST = {
          filter_key: state.queryTemporalPaciente,
          page: 100 * state.temporaPageComboPacientes,
          per_page: 1,
        };
        const RESPONSE = await axios.get('/clinic/patient/create/search', {
          params: REQUEST,
        });
        let pacientes = RESPONSE.data.data.data;
        if (pacientes === undefined) {
          console.error('la lista de pacientes llega como undefined de la api.');
          const MENSAJE_ERROR = `Error al traer la lista de pacientes,
          contactese con el Administrador.`;
          util.showNotify(MENSAJE_ERROR, 'error');
          pacientes = [];
        }
        const { total } = RESPONSE.data.data;
        commit('setPacientes', pacientes);
        commit('setTotalSearchPacientes', total);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        commit('setIsLoadingComboPacientes', false);
      }
    },
    async getDatosPacienteSelected({ commit, getters, state }, idPaciente) {
      try {
        const RESPONSE = await axios.get(`/clinic/patient/secure_medic/${idPaciente}`);
        const { paciente } = RESPONSE.data.data;
        /* const SEGURO_MEDICO = RESPONSE.data.data.seguro_medico; */
        const ESTADO_FACTURA = getters.isTipoFacturaConFactura;
        if (ESTADO_FACTURA) {
          commit('setRazonSocial', paciente.razon_social);
          commit('setNit', paciente.nit);
          commit('setComplemento', paciente.document_complement);
          if (!paciente.type_document_id) {
            commit('setIndentificacionSelected', state.tipoDocumentos[0]);
          } else {
            const IDENTIFICACION_SELECTED = state.tipoDocumentos.find((el) => el.id === paciente.type_document_id) ?? null;
            commit('setIndentificacionSelected', IDENTIFICACION_SELECTED);
          }
        }
        commit('facturacion/setPacienteToShow', paciente, { root: true });
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getDatosPacienteEditable({ commit }, idPaciente) {
      try {
        const RESPONSE = await axios.get(`/clinic/patient/secure_medic/${idPaciente}`);
        const { paciente } = RESPONSE.data.data;
        /* const SEGURO_MEDICO = RESPONSE.data.data.seguro_medico; */
        commit('facturacion/setPacienteToShow', paciente, { root: true });
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async verificarNitRepetido({ state }) {
      const REQUEST = {
        paciente_id: state.pacienteSelected.id,
        nit: state.nit,
      };
      try {
        const RESPONSE = await axios.get('/clinic/invoicing/search/nit', {
          params: REQUEST,
        });
        const datoFactura = RESPONSE.data.data.dato_factura;
        return (datoFactura === null);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      return false;
    },
    async tieneMedicoFacturaTercero({ state }) {
      if (state.medicoSelected === null) {
        return false;
      }
      const REQUEST = {
        medico_id: state.medicoSelected.id,
      };
      try {
        const RESPONSE = await axios.get('/clinic/invoicing/factura_tercero', {
          params: REQUEST,
        });
        return RESPONSE.data.data.tiene;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
      return false;
    },
    async obtenerTratamientosPendientes({ commit, state }) {
      try {
        const REQUEST = {
          persona_id: state.pacienteSelected.id,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/tratamiento', {
          params: REQUEST,
        });
        const { tratamientos } = RESPONSE.data.data;
        commit('modalTratamientoEnCurso/cargarListaTratamientos', tratamientos, { root: true });
        if (tratamientos.length > 0) {
          commit('setTratamientosDisponibles', true);
        } else {
          commit('setTratamientosDisponibles', false);
        }
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async synFacturaCreate({ commit, rootState, getters }, data) {
      try {
        const RESPONSE = await axios.post('/synFactura/facturation/create', data);
        const dataConexion = (RESPONSE.data.data.conexion.original) ? RESPONSE.data.data.conexion : RESPONSE.data.data.conexion.coneccion;

        const conexion = validarParametroFacturacionSiat(
          dataConexion,
          true,
          rootState.main.extrafacturaElectronica,
        );
        const conexionSiat = Array.isArray(conexion) ? false : conexion;
        commit('facturacion/setConexionSiat', conexionSiat, { root: true });

        const tipoEmisionSelected = !conexionSiat ? getters.getTipoEmisionsComputed.find((el) => el.cod_clasificador === 2) : getters.getTipoEmisionsComputed.find((el) => el.cod_clasificador === 1);
        commit('setTipoEmisionSelected', tipoEmisionSelected);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getLeyendasFacturador({ commit, rootState }, data) {
      try {
        const response = await axios.post('/synFactura/factura/show_leyendas', data);
        const dataLeyendas = response.data.data.leyendas;
        const siatLeyendas = validarParametroFacturacionSiat(
          dataLeyendas,
          true,
          rootState.main.extrafacturaElectronica,
        );

        const max = siatLeyendas.length;
        const posicion = Math.random() * (max - 0) + 0;
        const siatLeyendaSelected = siatLeyendas[parseInt(posicion, 10)];
        commit('setSiatLeyendaSelected', siatLeyendaSelected);
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    async getTratanteByServicioId({ commit }, servicioId) {
      try {
        commit('setMedicosTratantes', []);
        const RESPONSE = await axios.get(`/clinic/doctors/medico/${servicioId}/by_service`);
        commit('setMedicosTratantes', RESPONSE.data.data);
        return true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        return false;
      }
    },
    resetFormFactura({ commit, dispatch }) {
      commit('setModalidadesFacturas', []);
      commit('setHasOptionSinFactura', false);
      commit('setTipoFactura', MODALIDAD_FACTURACION.COMPUTARIZADA);
      commit('setTipoContingencias', []);
      commit('setTipoContingenciaSelected', null);
      commit('setTipoEmisions', []);
      commit('setTipoEmisionSelected', null);
      commit('setSiatLeyendaSelected', null);
      commit('setCafc', null);
      commit('setDisabledStatusFactura', false);
      commit('setCheckFacturaTercero', false);
      commit('setDatePicker', new Date());
      commit('setDisabledDatePicker', false);
      commit('setPacienteSelected', null);
      commit('setIndentificacionSelected', null);
      commit('setPacientes', []);
      commit('setIsLoadingComboPacientes', false);
      commit('setRazonSocial', null);
      commit('setNit', null);
      commit('setDatoFactura', false);
      commit('setShowDatoFactura', true);
      commit('setCheckSeguroEstado', false);
      commit('setNroFactura', null);
      commit('setAseguradoraSelected', null);
      commit('setComboAseguradora', []);
      commit('setMonedaSelected', null);
      commit('setComboMoneda', []);
      commit('setStatusComboMoneda', false);
      commit('setTipoCambio', null);
      commit('setTC', null);
      commit('setMedicoSelected', null);
      commit('setConfigFacturaElectronicaMedico', null);
      commit('setSiatMedioPagoMedico', []);
      commit('setSiatTipoDocumentoMedico', []);
      commit('setMedicos', []);
      commit('setMedicosTratantes', []);
      commit('setQueryTemporalPaciente', '');
      commit('setTemporaPageComboPacientes', 1);
      commit('setTotalSearchPacientes', 0);
      commit('setFacturaNumeroManual', false);
      commit('setTratamientosDisponibles', false);
      dispatch('modalTratamientoEnCurso/resetModalTratamientoEnCurso', null, { root: true });
      dispatch('modalCierreTratamiento/resetModalCierreTratamiento', null, { root: true });
      commit('setMedicoDerivador', []);
      commit('setNumeroReserva', null);
      commit('setComplemento', null);
      commit('setTipoDocumentos', []);
      commit('setNumeroTratamiento', null);
    },
  },
};
