import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state: {
    talla: null,
    indiceCorporal: null,
    frecuenciaCardiaca: null,
    peso: null,
    frecuenciaRespiratoria: null,
    saturacionOxigeno: null,
    oral: null,
    sistolica: null,
    diastolica: null,
    id: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setTalla: (state, data) => { state.talla = data; },
    setIndiceCorporal: (state, data) => { state.indiceCorporal = data; },
    setFrecuenciaCardiaca: (state, data) => { state.frecuenciaCardiaca = data; },
    setPeso: (state, data) => { state.peso = data; },
    setFrecuenciaRespiratoria: (state, data) => { state.frecuenciaRespiratoria = data; },
    setSaturacionOxigeno: (state, data) => { state.saturacionOxigeno = data; },
    setOral: (state, data) => { state.oral = data; },
    setSistolica: (state, data) => { state.sistolica = data; },
    setDiastolica: (state, data) => { state.diastolica = data; },
    setId: (state, data) => { state.id = data; },
    cargarDataSignoVitales(state, data) {
      state.id = data.id;
      state.talla = data.talla;
      state.peso = data.peso;
      state.oral = data.temperatura;
      state.indiceCorporal = data.indice_masa_corporal;
      state.frecuenciaRespiratoria = data.frecuencia_respiratoria;
      state.frecuenciaCardiaca = data.frecuencia_cardiaca;
      state.saturacionOxigeno = data.saturacion_oxigeno;
      state.sistolica = data.presion_arterial_sistolica;
      state.diastolica = data.presion_arterial_diastolica;
    },
  },
  actions: {
    resetSignosVitales({ commit }) {
      commit('setTalla', null);
      commit('setIndiceCorporal', null);
      commit('setFrecuenciaCardiaca', null);
      commit('setPeso', null);
      commit('setFrecuenciaRespiratoria', null);
      commit('setSaturacionOxigeno', null);
      commit('setOral', null);
      commit('setSistolica', null);
      commit('setDiastolica', null);
      commit('setId', null);
    },
  },
};
