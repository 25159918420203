import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  namespaced: true,
  state: {
    tablaModal: [],
  },
  getters: {
    getField,
    getIDItemChecked(state) {
      const ITEM = state.tablaModal.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return null;
      }
      return ITEM.id;
    },
    getCamaItemChecked(state) {
      const ITEM = state.tablaModal.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return '';
      }
      return ITEM.cama_nombre;
    },
    getPisoSalaItemChecked(state) {
      const ITEM = state.tablaModal.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return '';
      }
      return ITEM.piso_sala_nombre;
    },
    getImporteXDiaSalaCama(state) {
      const ITEM = state.tablaModal.find((item) => item.checkInput === true) ?? null;
      if (ITEM === null) {
        return 0;
      }
      return ITEM.importe_x_dia;
    },
  },
  mutations: {
    updateField,
    setTablaModal: (state, data) => { state.tablaModal = data; },
    cargarModalPieza(state, data) {
      const TEMP_ARRAY = [];
      data.forEach((el) => {
        const ITEM = {
          cama_nombre: el.cama_nombre,
          clase_nombre: el.clase_nombre,
          estado: el.estado,
          fecha: el.fecha,
          id: el.id,
          medico: el.medico,
          numero: el.numero,
          piso_sala_nombre: el.piso_sala_nombre,
          tratamiento: el.tratamiento,
          checkInput: false,
          importe_x_dia: el.importe_x_dia,
          motivo: el.motivo,
          tiempo: el.tiempo,
          // estado_tratamiento_cama: el.estado_tratamiento_cama,
        };
        TEMP_ARRAY.push(ITEM);
      });
      state.tablaModal = TEMP_ARRAY;
    },
    cargarSalaCamaID(state, data) {
      if (data !== null) {
        const INDEX = state.tablaModal.findIndex((el) => el.id === data);
        state.tablaModal[INDEX].checkInput = true;
      }
    },
    cargarSalaEdit(state, data) {
      if (data.sala_cama_id) {
        const ITEM = {
          cama_nombre: data.cama_nombre,
          clase_nombre: data.clase_nombre,
          estado: data.estado,
          fecha: data.fecha,
          id: data.sala_cama_id,
          medico: data.medico,
          numero: data.numero,
          piso_sala_nombre: data.piso_sala_nombre,
          tratamiento: data.tratamiento,
          checkInput: true,
          importe_x_dia: data.importe_x_dia,
          motivo: data.motivo,
          tiempo: data.tiempo,
        };
        state.tablaModal.push(ITEM);
      }
    },
    verificarModal(state) {
      state.tablaModal.forEach((el, index) => {
        state.tablaModal[index].checkInput = false;
      });
    },
  },
  actions: {
    resetModalPieza({ commit }) {
      commit('setTablaModal', []);
    },
    async listSalaCama({ commit }, dto) {
      const params = {
        tipo_tratamiento_id: dto.tipo_tratamiento_id,
        aseguradora_id: dto.aseguradora_id,
      };
      try {
        if (!dto.selected_piece) {
          // si no tiene seleccionada una pieza
          const RESPONSE = await axios.get('/clinic/tratamientos/cama/list', { params });
          commit('cargarModalPieza', RESPONSE.data.data.sala_camas);
        }
        return true;
      } catch (error) {
        const FALLO_VALIDACION = 422;
        if (error.response.status !== FALLO_VALIDACION) {
          util.showNotify(error.response.data.message, 'error');
        } else {
          Object.entries(error.response.data.data).forEach(([, mensajes]) => {
            mensajes.forEach((texto) => util.showNotify(texto, 'error'));
          });
        }
        return false;
      }
    },
  },
};
