import { getField, updateField } from 'vuex-map-fields';
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';

export default {
  namespaced: true,
  state: {
    temporalpageComboClasificacion: 1,
    totalSearchClasificacion: 0,
    isLoadingComboDiagnosticos: false,
    listClasificacionCies: [],
    listaClasificacionCIEFiltrada: [],
    queryTemporalClasificacion: null,
  },
  getters: {
    getField,
  },
  mutations: {
    updateField,
    setTemporaPageComboClasificacion: (state, data) => { state.temporalpageComboClasificacion = data; },
    setTotalSearchClasificacion: (state, data) => { state.totalSearchClasificacion = data; },
    setIsLoadingComboDiagnosticos: (state, data) => { state.isLoadingComboDiagnosticos = data; },
    setListClasificacionCies: (state, data) => { state.listClasificacionCies = data; },
    setListaClasificacionCieFiltrada: (state, data) => { state.listClasificacionCies = data; },
    setQueryTemporalClasificacion: (state, data) => { state.queryTemporalClasificacion = data; },
  },
  actions: {
    async obtenerListClasificacion({ commit, state }, query) {
      commit('setIsLoadingComboDiagnosticos', true);
      if (query !== null) {
        commit('setQueryTemporalClasificacion', query);
      } else {
        commit('setTemporaPageComboClasificacion', state.temporalpageComboClasificacion + 1);
      }
      try {
        const REQUEST = {
          page: 20 * state.temporalpageComboClasificacion,
          filter_key: state.queryTemporalClasificacion,
          per_page: 1,
        };
        const RESPONSE = await axios.get('/clinic/tratamientos/list_clasificacion', {
          params: REQUEST,
        });
        const LIST_CLASIFICACION = RESPONSE.data.data.data;
        commit('setListClasificacionCies', LIST_CLASIFICACION);
        const TOTAL_CLASIFICACION = RESPONSE.data.data.total;
        commit('setTotalSearchClasificacion', TOTAL_CLASIFICACION);
        return true;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
        return false;
      } finally {
        commit('setIsLoadingComboDiagnosticos', false);
      }
    },
    resetTheDiagnosticData({ commit }) {
      commit('setTemporaPageComboClasificacion', 1);
      commit('setTotalSearchClasificacion', 0);
      commit('setIsLoadingComboDiagnosticos', false);
      commit('setListClasificacionCies', []);
      commit('setListaClasificacionCieFiltrada', []);
      commit('setQueryTemporalClasificacion', null);
    },
  },
};
